<template>
  <div class="list">
    <ul>
      <li v-for="post in paginatedPosts" :key="post.fileName" class="item" >
        <router-link :to="{ name: 'BlogPost', params: { fileName: post.fileName } }"><h2>{{ post.title }}</h2></router-link>
        <div style="margin: 15px 0">
          <a-space :size="-12" class="avatar-chips">
            <a-avatar src="/images/img-logo.png" style="background-color: #FFFFFF; margin-left: 0" />
          </a-space>
          <span>Admin</span>
          <span>{{ formatDate(post.date) }}</span>
        </div>
        <p>{{ post.description }}</p>
        <router-link :to="{ name: 'BlogPost', params: { fileName: post.fileName } }">阅读全文</router-link>
      </li>
    </ul>
    <a-pagination
        v-if="posts.length > pageSize"
        style="margin-bottom: 50px;"
        :current="currentPage"
        :pageSize="pageSize"
        :total="posts.length"
        @change="handlePageChange"
    />
  </div>
</template>



<script>
export default {
  props: {
    posts: Array,
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 5,
    };
  },
  computed: {
    paginatedPosts() {
      const start = (this.currentPage - 1) * this.pageSize;
      return this.posts.slice(start, start + this.pageSize);
    },
  },
  methods: {
    handlePageChange(page) {
      this.currentPage = page;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return `${date.getFullYear()}年${String(date.getMonth() + 1).padStart(2, '0')}月${String(date.getDate()).padStart(2, '0')}日`;
    },
  },
};
</script>


<style scoped>
.list {
  width: 65%;
}
.item {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
}
ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 25px;
}

h2 {
  margin: 0;
  color: #63b4ff;
}

p {
  margin: 15px 0;
  font-size: 16px;
  line-height: 1.8;
}

a {
  font-size: 16px;
  color: #63b4ff;
  font-weight: bold;
  text-decoration: none;
}

span {
  margin-left: 10px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .list {
    width: 100%;
  }
  li {
    margin-bottom: 20px;
  }
  ul {
    margin-top: -10px;
  }
}
</style>
