<template>
  <div class="list">
    <BlogList :posts="posts" />
  </div>
</template>

<script>
import BlogList from '../components/Blog/BlogList.vue';

export default {
  components: {
    BlogList,
  },
  data() {
    return {
      posts: [],
    };
  },
  created() {
    this.loadPostsFromGitHub();
  },
  methods: {
    async loadPostsFromGitHub() {
      document.head.querySelector('link[rel="canonical"]').setAttribute('href', `https://copyapes.com/blog/`);
      const repo = 'hiEndless/copyapes_blog'; // 替换为你的 GitHub 用户名和仓库名
      const folder = 'content'; // 替换为你在 GitHub 中的文件夹名称
      const apiUrl = `https://api.github.com/repos/${repo}/contents/${folder}`;

      try {
        // 获取文件列表
        const response = await fetch(apiUrl);
        if (!response.ok) throw new Error(`Error fetching files: ${response.statusText}`);

        const files = await response.json();

        // 遍历文件列表并获取文件内容
        const posts = await Promise.all(
            files.map(async (file) => {
              if (file.type === 'file' && file.name.endsWith('.md')) {
                const contentResponse = await fetch(file.download_url);
                const content = await contentResponse.text();

                // 解析文件的元数据和内容
                const [meta, body] = content.split('---').slice(1, 3);
                const attributes = this.parseMeta(meta);

                return {
                  title: attributes.title || file.name,
                  description: attributes.description || '',
                  date: attributes.date || '',
                  content: body || '',
                  fileName: file.name.replace('.md', ''),
                };
              }
            })
        );

        this.posts = posts.filter(post => post).sort((a, b) => new Date(b.date) - new Date(a.date));
      } catch (error) {
        console.error('Error loading posts from GitHub:', error);
      }
    },
    parseMeta(meta) {
      const attributes = {};
      meta.split('\n').forEach((line) => {
        const [key, value] = line.split(':').map((str) => str.trim());
        if (key && value) attributes[key] = value;
      });
      return attributes;
    },
  },
};
</script>

<style scoped>
.list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(90vh - 100px);
  margin-top: 50px;
  @media (max-width: 576px) {
    margin-top: 70px;
  }
}
</style>
